<template>
  <div style="background: #F5F4F8;overflow: hidden">
    <div class="member_msg">
      <div class="member_title">
        <div class="member_line"></div>
        <div class="member_title_text">我的会员</div>
      </div>
      <div class="member_name">{{ userInfo.name }}</div>
      <div class="member_level isVip" v-if="isVip">
        <img src="@/assets/icon.png" alt />
        {{ vipInfo.gradeName }}
      </div>
      <div class="member_level" v-else>
        <img src="@/assets/vip-icon-not.png" alt />
        开通会员
      </div>
      <div class="member_date">会员到期时间：{{ formatTime(vipInfo.endTime,'YYYY-MM-DD HH:mm:ss') || '- -' }}</div>
    </div>
    <div class="member_content">
      <div class="member_content_title">
        <a-divider>会员购买</a-divider>
      </div>
      <div class="member_type">
        <div class="basic_item">
          <div class="vip_img"></div>
          <a-divider dashed></a-divider>
          <div class="vip_title">季度会员权益说明：</div>
          <div
            class="vip_content"
          >季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，</div>
          <div class="btn_box">
            <a-button type="primary" shape="round">立刻购买</a-button>
          </div>
        </div>
        <div class="basic_item">
          <div class="vip_img year"></div>
          <a-divider dashed></a-divider>
          <div class="vip_title">年度会员权益说明：</div>
          <div
            class="vip_content"
          >季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，季度会员相关权益的文本介绍，</div>
          <div class="btn_box">
            <a-button type="primary" shape="round">立刻购买</a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVipInfo } from '@/api/menber-vip.js'
export default {
  components: {},
  name: 'Member',
  data () {
    return {
      vipInfo: {}
    }
  },
  computed: {
    userInfo () {
      return this.$store.getters.userInfo
    },
    isVip () {
      return !!this.vipInfo.endTime
    }
  },
  created () {
    getVipInfo().then(res => {
      this.vipInfo = res.data || {}
    })
  }
}
</script>

<style scoped lang="less">
.member_level {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  line-height: 21px;
  margin: 10px 0;
  color: #454545;
  &.isVip {
    color: #ffab1f;
  }
}

.member_level img {
  width: 29px;
  height: 25px;
}
.member_msg {
  width: 1400px;
  background: #ffffff;
  height: 220px;
  // margin: 120px auto 0;
  border-radius: 4px;
  .member_name {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #383838;
    margin: 40px 0 10px;
  }
  .member_date {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 28px;
    color: #383838;
  }
  .member_title {
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #efefef;
    position: relative;
    .member_line {
      width: 3px;
      height: 20px;
      background: #1677ff;
      position: absolute;
      left: 20px;
      top: 21px;
    }
    .member_title_text {
      font-size: 20px;
      font-weight: bold;
      line-height: 60px;
      margin-left: 35px;
      color: #1677ff;
    }
  }
}

.member_content {
  .member_item {
    margin-top: 60px;
  }
  .member_content_title {
    width: 300px;
    margin: 20px auto 10px;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 33px;
    color: #151515;
  }
  .member_type {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .basic_item {
      width: 590px;
      height: 462px;
      padding: 24px;
      background: #ffffff;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 8px;
    }
    .vip_img {
      width: 260px;
      height: 80px;
      background: url(/img/vip_quarterly.png) 100% 100% no-repeat;
      margin: 0 auto 10px;
      &.year {
        background: url(/img/vip_year.png) 100% 100% no-repeat;
      }
    }
    .vip_title {
      font-size: 18px;
      color: #333333;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .btn_box {
      margin-top: 40px;
      text-align: center;
    }
  }
  .member_item_image {
    width: 140px;
    height: 140px;
    background: #fcadad;
    margin: 0 auto;
  }
  .member_item_title {
    font-size: 22px;
    font-weight: 600;
    line-height: 33px;
    color: #151515;
    text-align: center;
    margin: 10px 0 6px;
  }
  .member_item_text {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    color: #151515;
    margin: 20px auto 10px;
    width: 350px;
  }
}

.member_btn {
  width: 150px;
  height: 38px;
  border-radius: 24px;
  margin: 30px auto;
  display: block;
  font-size: 16px;
}
</style>
<style>
.ant-divider-inner-text {
  font-size: 20px;
  font-weight: 600;
}
</style>
